import { useState, useEffect, useCallback, useRef } from "react";

const usePostMessage = (eventHandler) => {
  const [origin, setOrigin] = useState();
  const [source, setSource] = useState();

  const originRef = useRef();
  const sourceRef = useRef();

  originRef.current = origin;
  sourceRef.current = source;

  const onWatchEventHandler = useCallback(
    ({ origin, source, data }) => {
      if (origin !== "https://form01.ted.com") {
        return;
      }

      setSource(source);
      setOrigin(origin);
      eventHandler(data);
    },
    [eventHandler, setSource, setOrigin]
  );

  useEffect(() => {
    window.addEventListener("message", onWatchEventHandler);
    return () => window.removeEventListener("message", onWatchEventHandler);
  }, [source, origin, onWatchEventHandler]);
};

export default usePostMessage;
