import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

import useTrackSubmisson from "app/hooks/use-track-submission";
import useQueryParams from "app/hooks/use-query-params";
import useFrameHeight from "app/hooks/use-frame-height";

import * as MarkdownStyles from "app/components/markdown-block.styles";
import * as Styles from "app/styles/shared";

const S = {
  ...MarkdownStyles,
  ...Styles,
};

const PardotScript = /* js */ `
piAId = '948812'; piCId = '77823'; piHostname = 'pi.pardot.com'; (function() { function async_load(){ var s = document.createElement('script'); s.type = 'text/javascript'; s.src = 'https://pi.pardot.com/pd.js'; var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c); } if(window.attachEvent) { window.attachEvent('onload', async_load); } else { window.addEventListener('load', async_load, false); } })();
`;

const formStyle = css`
  max-width: 650px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;
  background-color: white;
`;

const framePadding = css`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 60px;
`;

const PardotForm = ({ formHeight, pardotUrl, eventName, formClass }) => {
  const height = useFrameHeight(formHeight || 600);
  const queryParams = useQueryParams();

  useTrackSubmisson({
    formurl: pardotUrl,
    queryParams,
    eventName: eventName || "pardot-form-submission",
  });

  return (
    <>
      <Helmet>
        <script>{PardotScript}</script>
      </Helmet>
      <div css={[S.container, framePadding]}>
        <div css={formStyle} className={formClass}>
          {pardotUrl && (
            <iframe
              key={pardotUrl}
              css={css`
                height: ${height}px;
              `}
              src={`${pardotUrl}${queryParams}`}
              width="100%"
              type="text/html"
              frameBorder="0"
              allowtransparency="true"
              title="Request Demo Form"
              style={{ border: 0 }}
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
};

PardotForm.displayName = "PardotForm";

export default PardotForm;

export const pardotFormFields = [
  {
    label: "Form Url",
    name: "pardotUrl",
    widget: "string",
  },
  {
    label: "GTM: Submission event name",
    name: "eventName",
    default: "pardot-form-submission",
    widget: "string",
  },
  {
    label: "Form Height",
    name: "formHeight",
    widget: "number",
    min: 100,
    default: 600,
    value_type: "int",
  },
];

export const pardotFormBlockFragment = graphql`
  fragment PardotForm on ContentJsonPardotForm {
    pardotUrl
    eventName
    formHeight
  }
`;
