import get from "lodash.get";

import usePostMessage from "app/hooks/use-post-message";

const useTrackSubmisson = ({ formurl, queryParams, eventName }) => {
  usePostMessage((data) => {
    if (!window.dataLayer || get(data, "formSubmitted") !== true) {
      return;
    }

    window.dataLayer.push({
      event: eventName,
      formurl,
      queryParams,
    });
  });
};

export default useTrackSubmisson;
