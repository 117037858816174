import React, { useEffect, useState } from "react";
import get from "lodash.get";

import usePostMessage from "app/hooks/use-post-message";

const useFrameHeight = (fallback) => {
  const [height, setHeight] = useState(fallback);

  useEffect(() => {
    if (fallback) {
      setHeight(fallback);
    }
  }, [fallback, setHeight]);

  const resizeForm = React.useCallback(
    (data) => {
      const formHeight = parseInt(get(data, "formHeight"), 10);
      if (formHeight && formHeight !== height + 80) {
        setHeight(formHeight + 80);
      }
    },
    [setHeight, height]
  );

  usePostMessage(resizeForm);

  return height;
};

export default useFrameHeight;
